import { Controller } from '@hotwired/stimulus';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  connect() {
    this.buttonRipple = new MDCRipple(this.element);
  }

  disconnect() {
    if (this.buttonRipple) this.buttonRipple.destroy();
  }
}
