import { Controller } from '@hotwired/stimulus';
import { MDCLineRipple } from '@material/line-ripple';

export default class extends Controller {
  connect() {
    this.lineRipple = new MDCLineRipple(this.element);
  }

  disconnect() {
    if (this.lineRipple) this.lineRipple.destroy();
  }
}

