import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const scrollContainer = document.querySelector(this.scrollContainerSelector);
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
      scrollContainer.scrollLeft = 0;
    }
  }

  get scrollContainerSelector() {
    return this.data.get('scrollContainerSelector') || '.mdc-drawer-app-content';
  }
}
