import { Controller } from '@hotwired/stimulus';
import { MDCDrawer } from '@material/drawer';
import { MDCTopAppBar } from '@material/top-app-bar';

export default class extends Controller {
  connect() {
    this.topAppBar = new MDCTopAppBar(this.element.querySelector('.mdc-top-app-bar'));
    this.drawerElement = this.element.querySelector('.mdc-drawer');
    this.drawerAppContent = this.element.querySelector('.mdc-drawer-app-content');
    if (this.shouldActivateModalDrawer()) this.activateModalDrawer();
    window.addEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleNavToggle() {
    this.drawer.open = !this.drawer.open;
  }

  handleWindowResize() {
    if (this.shouldActivateModalDrawer()) {
      this.activateModalDrawer();
    } else {
      this.unactivateModalDrawer();
    }
  }

  activateModalDrawer() {
    if (this.activatedModalDrawer) return;
    this.drawerElement.classList.add('mdc-drawer--modal');
    this.drawerAppContent.classList.add('fullpage');
    this.drawer = MDCDrawer.attachTo(this.drawerElement);
    this.topAppBar.listen('MDCTopAppBar:nav', this.handleNavToggle.bind(this));
    this.activatedModalDrawer = true
  }

  unactivateModalDrawer() {
    if (!this.activatedModalDrawer) return;
    this.drawerElement.classList.remove('mdc-drawer--modal');
    this.drawerAppContent.classList.remove('fullpage');
    if (this.drawer) this.drawer.destroy();
    this.topAppBar.unlisten('MDCTopAppBar:nav', this.handleNavToggle.bind(this));
    this.activatedModalDrawer = false
  }

  shouldActivateModalDrawer() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }

  disconnect() {
    if (this.drawer) this.drawer.destroy();
    if (this.topAppBar) {
      this.topAppBar.unlisten('MDCTopAppBar:nav', this.handleNavToggle.bind(this));
      this.topAppBar.destroy();
    }
  }
}
