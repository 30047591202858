import { Controller } from '@hotwired/stimulus';
import { MDCFloatingLabel } from '@material/floating-label';

export default class extends Controller {
  connect() {
    this.floatingLabel = new MDCFloatingLabel(this.element);
  }

  disconnect() {
    if (this.floatingLabel) this.floatingLabel.destroy();
  }
}
