import { Controller } from '@hotwired/stimulus';
import PhotoSwipe from 'photoswipe/dist/photoswipe.min';
import PhotoSwipeUI_Default from 'photoswipe/src/js/ui/photoswipe-ui-default';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(event) {
    const pswpElement = document.querySelector('.pswp');
    if (!pswpElement) return;

    const image = event.target.closest('img.lazyload');
    if (!image) return;

    event.stopPropagation();

    const items = this.getImageInfoItems();
    const src = image.dataset.src
    const options = {
      index: items.findIndex(item => item.src === src),
      history: false,
      loop: false,
    };
    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
    pswpElement.dataset.gallery = gallery;
  }

  getImageInfoItems() {
    return [...this.element.querySelectorAll('img.lazyload')].map(item => {
      const size = item.dataset.size;
      const [width, height] = size ? size.split(',') : [0, 0];
      return {
        src: item.dataset.src,
        w: width ? width : item.naturalWidth,
        h: height ? height : item.naturalHeight,
      };
    });
  }

  disconnect() {
    if (!this.element) return;
    this.element.removeEventListener('click', this.handleClick.bind(this));
  }
}
