import { Controller } from '@hotwired/stimulus';
import { addBackToTop } from 'vanilla-back-to-top';

export default class extends Controller {
  connect() {
    const options = {
      diameter: 56,
      backgroundColor: '#ff6b70',
      textColor: '#fff',
      id: 'rocket-button',
    }
    if (this.scrollContainerSelector) {
      const scrollContainer = document.querySelector(this.scrollContainerSelector);
      if (scrollContainer) options.scrollContainer = scrollContainer;
    }
    addBackToTop(options);
  }

  get scrollContainerSelector() {
    return this.data.get('scrollContainerSelector') || '.mdc-drawer-app-content';
  }
}
