import { Controller } from '@hotwired/stimulus';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  connect() {
    this.iconButtonRipple = new MDCRipple(this.element);
  }

  disconnect() {
    if (this.iconButtonRipple) this.iconButtonRipple.destroy();
  }
}
