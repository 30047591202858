import { Controller } from '@hotwired/stimulus';
import { MDCSwitch } from '@material/switch';

export default class extends Controller {
  connect() {
    this.hidden_field = this.element.querySelector('input[type=hidden]');
    this.switch = new MDCSwitch(this.element);
    this.switch.selected = this.hidden_field.value === 'true';
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener(
      'click',
      this.handleClickEvent.bind(this),
    );
  }

  handleClickEvent() {
    this.hidden_field.value = this.switch.selected;
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClickEvent.bind(this));
    if (this.switch) { this.switch.destroy(); }
  }
}
