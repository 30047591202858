import { Controller } from '@hotwired/stimulus';
import { MDCDialog } from '@material/dialog';
import { MDCList } from '@material/list';

export default class extends Controller {
  connect() {
    this.dialog = new MDCDialog(this.element);
    let list = this.element.querySelector('.mdc-deprecated-list');
    if (list) {
      list = new MDCList(list);
      this.dialog.listen('MDCDialog:opened', () => list.layout() );
    }
  }

  disconnect() {
    if (!this.dialog) return
    this.dialog.destroy();
  }
}
