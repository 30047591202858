import { Controller } from '@hotwired/stimulus';
import { MDCTextField } from '@material/textfield';

export default class extends Controller {
  connect() {
    this.textField = new MDCTextField(this.element);
  }

  disconnect() {
    if (this.textField) this.textField.destroy();
  }
}
