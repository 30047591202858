import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener(
      'click',
      this.handleClick.bind(this),
    );
  }

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.header-search-input').value = `identity: ${this.data.get('id')}`;
    document.querySelector('.search-form').submit();
  }

  disconnect() {
    this.removeEventListeners();
  }

  removeEventListeners() {
    this.element.removeEventListener(
      'click',
      this.handleClick.bind(this),
    );
  }
}
