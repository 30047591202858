import { Controller } from '@hotwired/stimulus';
import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  connect() {
    this.list = new MDCList(this.element);
    this.listItemRipples = this.list.listElements.map(
      listItemElement => new MDCRipple(listItemElement)
    );
  }

  disconnect() {
    if (this.list) this.list.destroy();
    if (this.listItemRipples) {
      this.listItemRipples.forEach(
        listItemRipple => listItemRipple.destroy()
      );
    }
  }
}
