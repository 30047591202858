import c0 from './aria2_switch_controller';
import c1 from './download_controller';
import c2 from './drawer_nav_controller';
import c3 from './header_search_controller';
import c4 from './identity_search_controller';
import c5 from './layout_controller';
import c6 from './lazyload_controller';
import c7 from './material_components/button_controller';
import c8 from './material_components/dialog_controller';
import c9 from './material_components/floating_label_controller';
import c10 from './material_components/icon_button_controller';
import c11 from './material_components/line_ripple_controller';
import c12 from './material_components/list_controller';
import c13 from './material_components/snackbar_controller';
import c14 from './material_components/switch_controller';
import c15 from './material_components/text_field_controller';
import c16 from './material_components/text_field_helper_text_controller';
import c17 from './photoswipe_controller';
import c18 from './reset_scroll_controller';
import c19 from './rocket_controller';
export const definitions = [
	{identifier: 'aria2-switch', controllerConstructor: c0},
	{identifier: 'download', controllerConstructor: c1},
	{identifier: 'drawer-nav', controllerConstructor: c2},
	{identifier: 'header-search', controllerConstructor: c3},
	{identifier: 'identity-search', controllerConstructor: c4},
	{identifier: 'layout', controllerConstructor: c5},
	{identifier: 'lazyload', controllerConstructor: c6},
	{identifier: 'material-components--button', controllerConstructor: c7},
	{identifier: 'material-components--dialog', controllerConstructor: c8},
	{identifier: 'material-components--floating-label', controllerConstructor: c9},
	{identifier: 'material-components--icon-button', controllerConstructor: c10},
	{identifier: 'material-components--line-ripple', controllerConstructor: c11},
	{identifier: 'material-components--list', controllerConstructor: c12},
	{identifier: 'material-components--snackbar', controllerConstructor: c13},
	{identifier: 'material-components--switch', controllerConstructor: c14},
	{identifier: 'material-components--text-field', controllerConstructor: c15},
	{identifier: 'material-components--text-field-helper-text', controllerConstructor: c16},
	{identifier: 'photoswipe', controllerConstructor: c17},
	{identifier: 'reset-scroll', controllerConstructor: c18},
	{identifier: 'rocket', controllerConstructor: c19},
];
