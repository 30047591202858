import 'mousetrap/mousetrap.min';
import { MDCDialog } from '@material/dialog';

export function initMousetrap() {
  Mousetrap.bind('s t', () => {
    const scrollContainer = document.querySelector('.mdc-drawer-app-content');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    } else {
      window.scrollTo({ top: 0 });
    }
  });

  Mousetrap.bind('s b', () => {
    const scrollContainer = document.querySelector('.mdc-drawer-app-content');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    } else {
      window.scrollTo({ top: document.body.scrollHeight });
    }
  });

  Mousetrap.bind('s s', (event) => {
    if (/INPUT|TEXTAREA/.test(event.target.tagName)) return;

    event.preventDefault();
    const searchButton = document.querySelector('.header-search-button');
    if (searchButton) searchButton.click();
  });

  Mousetrap.bind('g d', () => {
    const downloadButton = document.querySelector('.detail .download-link');
    if (downloadButton) downloadButton.click();
  });

  Mousetrap.bind('g r', () => {
    const remoteButton = document.querySelector('.detail .remote');
    if (remoteButton) remoteButton.click();
  });

  Mousetrap.bind('g v', () => {
    const versionsButton = document.querySelector('.detail .versions');
    if (versionsButton) versionsButton.click();
  });

  Mousetrap.bind('g s', () => {
    const seriesButton = document.querySelector('.detail .series');
    if (seriesButton) seriesButton.click();
  });

  Mousetrap.bind(
    'g t',
    () => Turbo.visit('/torrents')
  );

  Mousetrap.bind(
    'g p',
    () => Turbo.visit('/previews')
  );

  Mousetrap.bind(
    'g i',
    () => Turbo.visit('/identities')
  );

  Mousetrap.bind('left', () => {
    const pswpOpening = document.querySelector('.pswp--open');
    if (pswpOpening) return;
    const prevLink = document.querySelector('#paginator-prev');
    if (prevLink) prevLink.click();
  });

  Mousetrap.bind('right', () => {
    const pswpOpening = document.querySelector('.pswp--open');
    if (pswpOpening) return;
    const nextLink = document.querySelector('#paginator-next');
    if (nextLink) nextLink.click();
  });

  Mousetrap.bind('?', () => {
    const shortcutsDialog = document.querySelector('.shortcuts-dialog');
    if (!shortcutsDialog) return;

    new MDCDialog(shortcutsDialog).open();
  });
};
