import { Controller } from '@hotwired/stimulus';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';

export default class extends Controller {
  connect() {
    this.helperText = new MDCTextFieldHelperText(this.element);
  }

  disconnect() {
    if (this.helperText) this.helperText.destroy();
  }
}
