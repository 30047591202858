import { Controller } from "@hotwired/stimulus";
import { MDCSnackbar } from "@material/snackbar";

export default class extends Controller {
  connect() {
    this.snackbar = new MDCSnackbar(this.element);
  }

  open() {
    this.snackbar.open();
  }

  disconnect() {
    if (this.snackbar) this.snackbar.destroy();
  }
}
