import { Controller } from '@hotwired/stimulus';
import LazyLoad from 'vanilla-lazyload';

export default class extends Controller {
  connect() {
    const options = {
      elements_selector: 'img.lazyload',
      thresholds: '500px 50%'
    };
    if (this.scrollContainerSelector) {
      const scrollContainer = document.querySelector(
        this.scrollContainerSelector
      );
      if (scrollContainer) options.container = scrollContainer;
    }

    this.lazyload = new LazyLoad(options);
  }

  get scrollContainerSelector() {
    return this.data.get('scrollContainerSelector') || '.mdc-drawer-app-content';
  }

  disconnect() {
    if (this.lazyload) this.lazyload.destroy();
  }
}
