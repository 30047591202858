import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.activeNavLink();
    this.highlightCurrentNavLink();
  }

  activeNavLink() {
    this.activeChannel();
    this.activeSection();
    this.activeType();
  }

  activeChannel() {
    const channelNavLink = this.element.querySelector(
      `.nav-link-channel[data-id=${this.data.get('channel')}]`
    );
    if (channelNavLink) channelNavLink.classList.add('active');
  }

  activeSection() {
    const sectionNavLink = this.element.querySelector(
      `.nav-link-section[data-id="${this.data.get('section')}"]`
    );
    if (sectionNavLink) sectionNavLink.classList.add('active');
  }

  activeType() {
    const typeNavLink = this.element.querySelector(
      `.nav-link-type[data-id="${this.data.get('type')}"]`
    );
    if (typeNavLink) typeNavLink.classList.add('active');
  }

  highlightCurrentNavLink() {
    const activeTypeNavLink = this.element.querySelector('.nav-link-type.active');
    if (activeTypeNavLink) {
      activeTypeNavLink.classList.add('current');
      return;
    }

    const activeSectionNavLink = this.element.querySelector('.nav-link-section.active');
    if (activeSectionNavLink) {
      activeSectionNavLink.classList.add('current');
      return;
    }

    const activeChannelNavLink = this.element.querySelector('.nav-link-channel.active');
    if (activeChannelNavLink) activeChannelNavLink.classList.add('current');
  }
}
