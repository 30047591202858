import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.handleDownloadScene();
  }

  handleDownloadScene() {
    switch (this.element.dataset.scene) {
      case 'aria2':
        this.openAria2Snackbar();
        break;
      case 'magnet':
      case 'torrent':
        this.element.dataset.scene = 'default';
        Turbo.visit(this.element.dataset.downloadLink);
        break;
      default:
        break;
    }
  }

  openAria2Snackbar() {
    const snackbar = document.querySelector('#aria2-snackbar');
    if (!snackbar) return;

    this.application
      .getControllerForElementAndIdentifier(
        snackbar,
        'material-components--snackbar'
      )
      .open();
  }
}
