import { Controller } from '@hotwired/stimulus';
import { MDCSwitch } from '@material/switch';

export default class extends Controller {
  connect() {
    this.switch = new MDCSwitch(this.element);
  }

  disconnect() {
    if (this.switch) this.switch.destroy();
  }
}
