import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.searchButton = this.element.querySelector('.header-search-button');
    this.searchFormWrapper = this.element.querySelector('.header-search-form-wrapper');
    this.searchInput = this.searchFormWrapper.querySelector('.header-search-input');
    this.closeButton = this.searchFormWrapper.querySelector('.header-search-close-button');
    this.addEventListeners();
  }

  addEventListeners() {
    this.searchButton.addEventListener(
      'click',
      this.handleSearchButtonClick.bind(this),
    );
    this.searchInput.addEventListener(
      'keydown',
      this.handleKeydownEvent.bind(this),
    );
    this.closeButton.addEventListener(
      'click',
      this.handleCloseButtonClick.bind(this),
    );
  }

  handleSearchButtonClick() {
    this.searchFormWrapper.classList.add('active');
    this.searchInput.focus();
  }

  handleKeydownEvent(event) {
    if (event.key === 'Escape') this.closeSearchForm();
  }

  handleCloseButtonClick() {
    this.closeSearchForm();
  }

  closeSearchForm() {
    this.searchFormWrapper.classList.remove('active');
    this.searchInput.value = '';
    this.searchInput.blur();
  }

  disconnect() {
    if (this.searchButton) {
      this.searchButton.removeEventListener(
        'click',
        this.handleSearchButtonClick.bind(this),
      );
    }
    if (this.searchInput) {
      this.searchInput.removeEventListener(
        'keydown',
        this.handleKeydownEvent.bind(this),
      )
    }
    if (this.closeButton) {
      this.closeButton.removeEventListener(
        'click',
        this.handleCloseButtonClick.bind(this),
      )
    }
  }
}
